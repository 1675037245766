import React, { FC } from 'react'
import styles from 'styles/components/flex.module.scss'

type Props = {
  alignItems?: 'start' | 'end' | 'center'
  justifyContent: 'start' | 'end' | 'between' | 'center' | 'around'
  itemNum?: string
  wrap?: 'wrap' | 'nowrap'
}

const FlexRow: FC<Props> = (props) => {
  const { alignItems, children, justifyContent, itemNum, wrap } = props

  const alignStyle = alignItems ? 'flex__row_align_' + alignItems : ''
  const rowStyle = 'flex__row_' + justifyContent
  const itemStyle = itemNum ? 'flex__row__item_' + itemNum : ''
  const wrapStyle = wrap ? 'flex__row_' + wrap : ''
  const className = itemNum
    ? `${styles[rowStyle]} ${styles[itemStyle]} ${styles[alignStyle]} ${styles[wrapStyle]}`
    : `${styles[rowStyle]} ${styles[alignStyle]} ${styles[wrapStyle]}`
  return <div className={className}>{children}</div>
}

export default FlexRow
