import React, { ChangeEvent, FC } from 'react'
import styles from 'styles/components/form.module.scss'
import { FormLabel } from 'components/Label'
import { Spacer } from 'components/Utility'

type Props = {
  disabled: boolean
  error?: string
  hidden?: boolean
  icon?: string
  id?: string
  isFilled?: boolean // 入力済みかどうか
  label?: string
  maxLength?: number
  maxWidth?: number
  onBlur?: () => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  onInput?: () => void
  placeholder?: string
  required: boolean
  size?: string
  type: string
  value: string | number
}

const TextInput: FC<Props> = React.memo((props) => {
  const {
    disabled,
    error,
    hidden,
    icon,
    id,
    isFilled,
    label,
    maxLength,
    maxWidth,
    onBlur,
    onChange,
    onClick,
    onInput,
    placeholder = '',
    required,
    size = 'medium',
    type,
    value,
  } = props

  const colorStyle = (() => {
    switch (true) {
      case disabled:
        return styles.text__input_disabled
      case error && isFilled:
        return styles.text__input__error_filled
      case error && !isFilled:
        return styles.text__input__error
      case !error && isFilled:
        return styles.text__input_filled
      case !error && !isFilled:
        return styles.text__input
      default:
        return styles.text__input
    }
  })()

  const sizeStyle = (() => {
    switch (size) {
      case 'small':
        return styles.text__input_small
      case 'medium':
        return styles.text__input_medium
      default:
        return styles.text__input_medium
    }
  })()

  const className = `${colorStyle} ${sizeStyle}`

  return (
    <div className={styles.text__wrapper}>
      {label && (
        <>
          <FormLabel id={id} label={label} required={required} />
          <Spacer size={8} />
        </>
      )}
      <div className={styles.text} style={{ maxWidth: maxWidth }}>
        <input
          className={className}
          hidden={hidden}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={(e) => (onChange ? onChange(e) : null)}
          onInput={onInput}
          required={required}
          type={type}
          value={value}
        />
        {icon && <img className={styles.text__icon} onClick={onClick} src={icon} />}
      </div>
      {error && error.length > 0 && <p className={styles.text__error}>{error}</p>}
    </div>
  )
})

export default TextInput
