import React, { FC } from 'react'
import styles from 'styles/components/label.module.scss'
import { Spacer } from 'components/Utility'
import { RequiredLabel } from 'components/Label'

type Props = {
  id?: string
  label: string
  required: boolean
}

const FormLabel: FC<Props> = (props) => {
  const { id, label, required } = props
  return (
    <label className={styles.form} id={id}>
      <span className={styles.form__text}>{label}</span>
      <Spacer size={4} axis={'horizontal'} />
      {required && <RequiredLabel />}
    </label>
  )
}

export default FormLabel
