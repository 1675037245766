import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import styles from 'styles/components/layout.module.scss'

type Props = {
  title: string
}

const Layout: FC<Props> = (props) => {
  const { children, title } = props

  return (
    <>
      <Helmet>
        <title>{title} | sacri for owner</title>
      </Helmet>
      <main className={styles.main}>
        <div className={styles.logo}>
          <img src={'/logo/logo-with-bread.svg'} />
        </div>
        <section className={styles.section}>{children}</section>
      </main>
    </>
  )
}

export default Layout
