import React, { FC } from 'react'
import styles from 'styles/components/loading.module.scss'
import { useSelector } from 'react-redux'
import { State } from 'index'
import { getLoadingState } from 'reducks/loading/selectors'

const Loading: FC = () => {
  const isLoading = useSelector((state: State) => getLoadingState(state))

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <div className={styles.bounce1} />
        <div className={styles.bounce2} />
        <div className={styles.bounce3} />
      </div>
    )
  } else {
    return <></>
  }
}

export default Loading
