export const VALID_ACCOUNTS: string[] = [
  '@atsuko_1211',
  '@_____.mf_',
  '@1217eri1',
  '@ah_s0106',
  '@halohalofuki',
  '@miroriy',
  '@hidamarineco',
  '@pajapaja_ma',
  '@tachyon884',
  '@chaaacoo_',
  '@sima00121',
  '@umikoga10969',
  '@piro10345',
  '@e_corobagel',
  '@ari.pan.man',
  '@joha_pan',
  '@kim.bagel__',
  '@yu._.pan',
  '@supimasa35',
  '@riemoco416',
  '@nacco_coco',
  '@yuuu1166',
  '@jmey199512',
  '@rururu0821',
  '@ccinoccino11',
  '@shiori_430',
  '@marilyne810',
  '@youweipingsong',
  '@sa_ya198',
  '@harupan896',
  '@peach1477',
  '@kekopen9181',
  '@omatsu1733',
  '@kt33811',
  '@oimopan0160',
  '@rena013007',
  '@ichheiseyasue',
  '@opicochan',
  '@iureius',
  '@otarms',
  '@22_e_ri',
  '@caramel_1_',
  '@miimom023',
  '@2gs1b',
  '@mashibaoban',
  '@maiko_temmei',
  '@h.anne_an',
  '@asami_star',
  '@you._.0912',
  '@ycyt_432',
  '@shiro7374',
  '@okin256',
  '@mugi_house',
  '@hitooozu',
  '@cherie.paris',
  '@cathy073070_bgl',
  '@bagelpannachan',
  '@kenbishi.saki',
  '@y24m26a20',
  '@wami281118',
  '@uchi3524a',
  '@rei1213.h',
  '@panon____3',
  '@orangemilk24',
  '@noripu53',
  '@moe_0417_g',
  '@mi_36_lol',
  '@meitani_22',
  '@kinchan.m_m',
  '@kennn313',
  '@kaaachan624',
  '@jisuikiroku08',
  '@bagel_kuma',
  '@_sukocyo_',
  '@tomomi.m.tomomi',
  '@nao.trk.215',
  '@chisa6009',
  '@sk_24',
  '@mai.2_mom',
  '@poyonn0220',
]
