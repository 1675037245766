import React from 'react'
import { Route, Switch } from 'react-router'
import { Top, ZermattComplete, ZermattError, ZermattTop } from 'pages'

const Router = () => (
  <Switch>
    <Route exact path="/" component={Top} />
    <Route exact path="/zermatt/202109" component={ZermattTop} />
    <Route exact path="/zermatt/202109/complete" component={ZermattComplete} />
    <Route exact path="/zermatt/202109/error" component={ZermattError} />
  </Switch>
)
export default Router
