import React, { FC } from 'react'
import { Description, Headline } from 'components/Typography'
import { Spacer } from 'components/Utility'
import styles from 'styles/components/template.module.scss'

const ZermattCompleteTemplate: FC = () => {
  return (
    <div className={styles.standard}>
      <Spacer size={16} />
      <Headline headline={'購入が完了しました'} />
      <Spacer size={48} />
      <Description
        description={
          '確認メールをご記載のアドレス宛にお送りしました。\n\n商品の発送・お問い合わせは、Zermattまで\n直接お問い合わせください'
        }
      />
    </div>
  )
}

export default ZermattCompleteTemplate
