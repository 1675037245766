import React, { Dispatch, SetStateAction, useCallback } from 'react'

const useStringChangeEvent = (
  update: Dispatch<SetStateAction<string>>,
  setIsEdited?: Dispatch<SetStateAction<boolean>>
) => {
  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      update(event.target.value)
      setIsEdited && setIsEdited(true)
    },
    [update, setIsEdited]
  )
}

export default useStringChangeEvent
