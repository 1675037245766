import React, { FC, useCallback, useState } from 'react'
import styles from 'styles/components/template.module.scss'
import text from 'styles/utility/text.module.scss'
import { STRIPE_PRICE_ID, SITE_URL } from 'constants/config'
import { useStringChangeEvent } from 'hooks/form'
import { isValidEmailFormat, isValidInstagram, isValidPhoneNumber } from 'lib/validator'
import { SessionRequest } from 'types/session'
import { ScrollableBox, Spacer } from 'components/Utility'
import { Headline, SubDescription } from 'components/Typography'
import { FormLabel } from 'components/Label'
import { TextInput } from 'components/Form'
import { RedButton } from 'components/Button'
import { doSessionRequest } from 'reducks/session/operations'
import { useDispatch } from 'react-redux'
import { VALID_ACCOUNTS } from 'constants/instagram'

const ZermattTopTemplate: FC = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const [instagram, setInstagram] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [instagramError, setInstagramError] = useState<string>('')
  const [phoneNumberError, setPhoneNumberError] = useState<string>('')

  const handleEmailChange = useStringChangeEvent(setEmail)
  const handleInstagramChange = useStringChangeEvent(setInstagram)
  const handleTelChange = useStringChangeEvent(setPhoneNumber)

  // Email Validation
  const validateEmail = useCallback(
    (email: string) => {
      if (email !== '' && !isValidEmailFormat(email)) {
        setEmailError('メールアドレスの形式が正しくありません。')
      } else {
        setEmailError('')
      }
    },
    [email, setEmailError]
  )

  // Instagram Validation
  const validateInstagram = useCallback(
    (instagram: string) => {
      if (instagram !== '' && !isValidInstagram(instagram)) {
        setInstagramError('Instagramアカウントの形式が正しくありません。@も含めてご入力ください。')
      } else if (instagram !== '' && !VALID_ACCOUNTS.includes(instagram)) {
        setInstagramError('抽選対象外のアカウントです。')
      } else {
        setInstagramError('')
      }
    },
    [instagram, setInstagramError]
  )

  // Phone Number Validation
  const validatePhoneNumber = useCallback(
    (phoneNumber: string) => {
      if (phoneNumber !== '' && !isValidPhoneNumber(phoneNumber)) {
        setPhoneNumberError('電話番号の形式が正しくありません。')
      } else {
        setPhoneNumberError('')
      }
    },
    [phoneNumber, setPhoneNumberError]
  )

  const toStripe = async () => {
    const sessionRequest: SessionRequest = {
      stripe_price_id: STRIPE_PRICE_ID,
      success_url: SITE_URL + '/zermatt/202109/complete',
      cancel_url: SITE_URL + '/zermatt/202109/error',
      email: email,
      instagram: instagram,
      phone_number: phoneNumber,
    }
    return dispatch(doSessionRequest(sessionRequest))
  }

  return (
    <div className={styles.standard}>
      <Spacer size={16} />
      <Headline headline={'Zermattのおまかせ通販セットを購入します'} />
      <Spacer size={28} />
      <FormLabel label={'メールアドレス'} required={true} />
      <Spacer size={8} />
      <TextInput
        disabled={false}
        error={emailError}
        onBlur={() => validateEmail(email)}
        onChange={handleEmailChange}
        placeholder={'zermatt@sacri.jp'}
        required={true}
        size={'small'}
        type={'email'}
        value={email}
      />
      <Spacer size={28} />
      <FormLabel label={'Instagramアカウント'} required={true} />
      <Spacer size={8} />
      <SubDescription description={'お客さまのInstagramアカウント名を入力してください。@も含めてご入力ください。'} />
      <Spacer size={8} />
      <TextInput
        disabled={false}
        error={instagramError}
        onBlur={() => validateInstagram(instagram)}
        onChange={handleInstagramChange}
        placeholder={'@sacri_official'}
        required={true}
        size={'small'}
        type={'text'}
        value={instagram}
      />
      <Spacer size={28} />
      <FormLabel label={'電話番号'} required={true} />
      <Spacer size={8} />
      <SubDescription description={'ハイフンはなしでご登録ください'} />
      <Spacer size={8} />
      <TextInput
        disabled={false}
        error={phoneNumberError}
        onBlur={() => validatePhoneNumber(phoneNumber)}
        onChange={handleTelChange}
        placeholder={'09012345678'}
        required={true}
        size={'small'}
        type={'text'}
        value={phoneNumber}
      />
      <Spacer size={28} />
      <ScrollableBox>
        <p className={text.font_bold}>
          <span>利用規約</span>
        </p>
        <p className={text.wordBreakAll}>
          ・本ページで取り扱う個人情報は、株式会社sacriがZermattより委託され、商品の販売と発送を目的として取得と利用を行います。
          <br />
          ・またクレジットカード情報はストライプジャパン株式会社の運営する決済代行サービス「Stripe」によって保持され、当社は取得しません。
          <br />
          「Stripe」の利用規約およびプライバシーポリシーについては同社のウェブサイト（
          <a href={'https://stripe.com/jp/legal'} target={'_blank'} rel={'noopener nofollow'}>
            https://stripe.com/jp/legal
          </a>
          ）にてご確認ください。
          <br />
          ・商品の発送、状態に関するお問い合わせは、Zermattへ直接お問い合わせください。
        </p>
      </ScrollableBox>
      <Spacer size={28} />
      <RedButton
        disabled={
          email === '' ||
          instagram === '' ||
          phoneNumber === '' ||
          emailError !== '' ||
          instagramError !== '' ||
          phoneNumberError !== ''
        }
        label={'決済画面へすすむ'}
        onClick={toStripe}
        size={'medium'}
        type={'main'}
      />
      <Spacer size={28} />
      <SubDescription description={'※Stripeのページへ遷移します。'} />
      <Spacer size={72} />
    </div>
  )
}

export default ZermattTopTemplate
