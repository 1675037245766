import React, { FC } from 'react'
import { Layout } from 'components/Layout'
import { ZermattTopTemplate } from 'components/Templates'

const ZermattTop: FC = () => {
  return (
    <Layout title={'Zermattのおまかせ通販セットを購入します'}>
      <ZermattTopTemplate />
    </Layout>
  )
}

export default ZermattTop
