import React, { FC } from 'react'
import styles from 'styles/components/typography.module.scss'
import text from 'styles/utility/text.module.scss'
import HTMLReactParser from 'html-react-parser'
import { returnCodeToBr } from 'lib/helper'

type Props = {
  align?: 'center' | 'left' | 'right'
  description: string
}

const Description: FC<Props> = React.memo((props) => {
  const { align, description } = props
  const alignStyle = align ? text['align_' + align] : ''
  const className = `${styles.description} ${alignStyle}`

  return <p className={className}>{HTMLReactParser(returnCodeToBr(description))}</p>
})

export default Description
