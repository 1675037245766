import React, { FC } from 'react'
import styles from 'styles/components/typography.module.scss'
import HTMLReactParser from 'html-react-parser'
import { returnCodeToBr } from 'lib/helper'

type Props = {
  description: string
  size?: 'small' | 'medium'
}

const SubDescription: FC<Props> = (props) => {
  const { description, size } = props
  const subDescription = (() => {
    switch (size) {
      case 'small':
        return styles.description_sub_small
      case 'medium':
        return styles.description_sub
      default:
        return styles.description_sub
    }
  })()

  return <p className={subDescription}>{HTMLReactParser(returnCodeToBr(description))}</p>
}

export default SubDescription
