import React, { FC } from 'react'
import { Layout } from 'components/Layout'
import { ZermattCompleteTemplate } from 'components/Templates'

const ZermattComplete: FC = () => {
  return (
    <Layout title={'購入が完了しました'}>
      <ZermattCompleteTemplate />
    </Layout>
  )
}

export default ZermattComplete
