import React from 'react'
import Router from 'Router'
import { Loading } from 'components/Utility'

const App = () => {
  return (
    <>
      <Router />
      <Loading />
    </>
  )
}

export default App
