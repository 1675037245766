import * as Actions from 'reducks/loading/actions'
import { initialState } from 'reducks/reduxStore/initialState'

export const LoadingReducer = (state = initialState.loading, action: any) => {
  switch (action.type) {
    case Actions.SHOW_LOADING:
      return {
        state: action.payload,
      }
    case Actions.HIDE_LOADING:
      return {
        state: action.payload,
      }
    default:
      return state
  }
}
