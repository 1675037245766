export const isValidUrlString = (url: string): boolean => {
  if (url === '') return true
  const regexp = new RegExp("^https?://[\\w!?/+\\-_~;.,*&@#$%()'[\\]]+$", 'g')
  return regexp.test(url)
}

export const isNumerics = (text: string): boolean => {
  if (text === '') return true
  return /^\d*$/.test(text)
}

export const isValidPhoneNumber = (text: string): boolean => {
  if (text === '') return true
  return /^0\d{9,10}$/.test(text)
}

export const isValidInstagram = (text: string): boolean => {
  if (text === '') return true
  return /^@[0-9a-zA-Z_/.]+[^/.]$/.test(text)
}

export const isValidEmailFormat = (email: string): boolean => {
  if (email === '') return true
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
}
