import React, { FC } from 'react'
import styles from 'styles/components/button.module.scss'
import { Spacer } from 'components/Utility'
import { FlexRow } from 'components/Flex'

type Props = {
  disabled?: boolean
  iconPath?: string
  label: string
  onClick: () => void
  size: 'xsmall' | 'small' | 'medium' | 'large'
  type: 'light' | 'main' | 'dark'
}

const RedButton: FC<Props> = React.memo((props) => {
  const { disabled, iconPath, label, onClick, size, type } = props

  const sizeStyle = (() => {
    switch (size) {
      case 'xsmall':
        return styles.button_xsmall
      case 'small':
        return styles.button_small
      case 'medium':
        return styles.button_medium
      case 'large':
        return styles.button_large
      default:
        return styles.button_medium
    }
  })()

  const colorStyle = (() => {
    switch (type) {
      case 'light':
        return styles.button_red_light
      case 'main':
        return styles.button_red_main
      case 'dark':
        return styles.button_red_dark
      default:
        return styles.button_red_main
    }
  })()

  const className = disabled ? `${sizeStyle} ${styles.button_grey_disabled}` : `${sizeStyle} ${colorStyle}`

  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      <FlexRow justifyContent={'center'}>
        {iconPath && (
          <>
            <img src={iconPath} alt={label} />
            <Spacer size={4} />
          </>
        )}
        {label}
      </FlexRow>
    </button>
  )
})

export default RedButton
