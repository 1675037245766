export const SHOW_LOADING = 'SHOW_LOADING'
export const showLoadingAction = () => {
  return {
    type: 'SHOW_LOADING',
    payload: true,
  }
}

export const HIDE_LOADING = 'HIDE_LOADING'
export const hideLoadingAction = () => {
  return {
    type: 'HIDE_LOADING',
    payload: false,
  }
}
