import React, { FC } from 'react'
import styles from 'styles/components/headline.module.scss'

type Props = {
  headline: string
}

const Headline: FC<Props> = (props) => {
  const { headline } = props

  return <h2 className={styles.headline}>{headline}</h2>
}

export default Headline
