import axios from 'axios'
import { SessionRequest } from 'types/session'
import { API_ENDPOINT } from 'constants/config'
import { hideLoadingAction, showLoadingAction } from 'reducks/loading/actions'
import { Action } from 'redux'
import { Dispatch } from 'redux'

export const doSessionRequest = (sessionRequest: SessionRequest) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(showLoadingAction())
    const url = `${API_ENDPOINT}/apiPaymentCreateSession`
    try {
      const res = await axios.post(url, sessionRequest, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      dispatch(hideLoadingAction())
      window.location.href = res.data.url
      return
    } catch (e) {
      dispatch(hideLoadingAction())
      console.error(e.response.data)
      console.error(e.response.status)
      if (e.response.status === 204) {
        alert('予期せぬエラーが発生しました')
        console.error(e.response.data.error_message)
        return
      }

      if (e.response.status === 400) {
        alert(e.response.data.error_message)
        console.error(e.response.data.error_message)
        return
      }
    }
  }
}
