import React, { FC } from 'react'
import { Layout } from 'components/Layout'
import { ZermattErrorTemplate } from 'components/Templates'

const ZermattError: FC = () => {
  return (
    <Layout title={'購入が失敗しました'}>
      <ZermattErrorTemplate />
    </Layout>
  )
}

export default ZermattError
