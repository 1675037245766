import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import * as History from 'history'
import 'styles/base.module.scss'
import App from 'App'
import createStore from 'reducks/reduxStore/store'
import reportWebVitals from './reportWebVitals'

// connected-react-router - action経由でルーティングが可能、push,replace..
// historyを強化
const history = History.createBrowserHistory()
export const store = createStore(history)
export type State = ReturnType<typeof store.getState>

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

reportWebVitals(console.log)
