import React, { FC } from 'react'
import styles from 'styles/components/template.module.scss'
import { Spacer } from 'components/Utility'
import { Description, Headline } from 'components/Typography'
import { RedButton } from 'components/Button'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

const ZermattErrorTemplate: FC = () => {
  const dispatch = useDispatch()

  return (
    <div className={styles.standard}>
      <Spacer size={16} />
      <Headline headline={'購入が失敗しました'} />
      <Spacer size={48} />
      <Description description={'ページの有効期限が切れています。\nお手数ですが、最初からやり直してください。'} />
      <Spacer size={48} />
      <RedButton
        label={'購入画面へ戻る'}
        onClick={() => dispatch(push('/zermatt/202109'))}
        size={'medium'}
        type={'main'}
      />
    </div>
  )
}

export default ZermattErrorTemplate
