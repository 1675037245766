import React, { FC } from 'react'
import { Layout } from 'components/Layout'
import { TopTemplate } from 'components/Templates'

const Top: FC = () => {
  return (
    <Layout title={'トップページ'}>
      <TopTemplate />
    </Layout>
  )
}

export default Top
