export const { API_ENDPOINT, STRIPE_PRICE_ID, SITE_URL } = (() => {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_PROJECT_ID === 'sacri-6bae3') {
    return {
      API_ENDPOINT: 'https://asia-northeast1-sacri-6bae3.cloudfunctions.net',
      STRIPE_PRICE_ID: 'price_1JVDPEFPbHeuMsR09BEeraTg',
      SITE_URL: 'https://payment.sacri.jp',
    }
  } else {
    return {
      API_ENDPOINT: 'https://asia-northeast1-yktt-b7f92.cloudfunctions.net',
      STRIPE_PRICE_ID: 'price_1JQnvQFPbHeuMsR0ssO1pT8S',
      SITE_URL: 'https://dev-sacri-payment.web.app',
    }
  }
})()
